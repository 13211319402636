import { useStorage } from '@vueuse/core'
import { increment as incrementUserEvent } from "@/stores/feedbackStore"
import { form as applyForm } from "@/components/apply/useApply"
import * as Turbo from "@hotwired/turbo"

const state = useStorage('userData', {
  jobs: [] as CornerCardJob[],
  codes: [] as ValidationSucceed[],
})

import csrfToken from "@/utils/csrfToken"

const validateCode = async (code: string) => {
  const request = await fetch("/job_applications/check_code", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken.value || "",
    },
    body: JSON.stringify({
      code
    }),
  })
  const response = await request.json() as ValidationError | ValidationSucceed
  // if (request.status === 200 && response.status == 'success') {
  //   return response
  // } else {
  //   return response
  // }
  return response
}

const addCode = async (code: ValidationSucceed) => {
  const currentState = state.value
  if (currentState.codes.find(e => e.code == code.code)) return
  state.value.codes.push(code)
  applyForm.value.empfehlungscodes.push(code)

  // rails session info hinzufügen
  try {
    const response = await fetch('/bewerber/add_recommendation_info', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.value || "",
      },
      body: JSON.stringify({ 
        username: code.user_name, 
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        Turbo.visit(window.location.href, { action: 'replace' });
      }
    }

  } catch (error) {
    console.error("Netzwerkfehler beim Hinzufügen der Session Infos:", error);
  }
}

const removeCode = async (code: ValidationSucceed) => {
  state.value.codes = state.value.codes.filter((c) => c.code !== code.code)
  applyForm.value.empfehlungscodes = applyForm.value.empfehlungscodes.filter((c) => c.code !== code.code)

  // rails session entfernen
  try {
    const response = await fetch('/bewerber/remove_recommendation_info', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken.value || "",
      },
      body: JSON.stringify ({
        username: code.user_name,
      }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.success) {
        const url = new URL(window.location.href);
        url.searchParams.delete('recommendation_code_validation_form[code]');
        window.history.replaceState({}, document.title, url.toString());
        Turbo.visit(window.location.href, { action: 'replace' });
      }
    }

  } catch (error) {
    console.error("Netzwerkfehler beim Löschen der Session Infos:", error);
  }
}

const bookmarkJob = (job: CornerCardJob) => {
  incrementUserEvent("cartAdded")
  state.value.jobs.push(job)
}

const removeBookmark = (job: CornerCardJob) => {
  incrementUserEvent("cartRemoved")
  state.value.jobs = state.value.jobs.filter((j) => j.id !== job.id)
}

const jobIsAdded = (job: CornerCardJob) => {
  return state.value.jobs.find((cartJob) => cartJob.id == job.id)
}

const clearCart = () => {
  state.value.jobs = []
  state.value.codes = []
  localStorage.removeItem('apply-cache')
}

export {
  bookmarkJob,
  removeBookmark,
  jobIsAdded,
  addCode,
  removeCode,
  clearCart,
  validateCode,
  state,
}
