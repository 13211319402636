<template lang="pug">
div
  div.job-description(v-html='job.description')

  hr
  .mt-3.mb-5(ref='mainEl')
    h2.text-2xl.font-bold.mb-4
      | {{$t("js.components.apply_card.title")}}
    apply-form(:job='applyJob' v-if='job')
  hr

  div.mt-3(v-if='job.tag_list')
    small.text-muted {{ $t('js.wizard.job_modal.schlagworte') }}
    div.d-flex.flex-wrap.gap-1
      span.badge.bg-primary.badge(v-for='tag in job.tag_list.slice(0, 15)')
        | {{tag }}

  div.mt-3
    small.text-muted {{ $t('js.wizard.job_modal.arbeitsort') }}
    div.d-flex.flex-wrap.gap-1
      span.badge.bg-primary.badge-pill(v-for='location in job.location_list')
        | {{location }}

  div.d-flex.gap-3.mt-5
    person-card(v-for='person in people' :key='person.id' style='max-width: 200px' :person='person')

</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue"
import ahoy from "@/utils/ahoy"
import PersonCard from "@/wizard/components/PersonCard.vue"
import ApplyForm from "@/components/apply/ApplyForm.vue"

const props = defineProps<{
  job: frontend.Job
  organisation: frontend.Organisation
}>()

const people = computed(() => {
  return props.organisation.visible_people.filter((person) => {
    return props.job.person_ids.includes(person.id)
  })
})

const applyJob = computed(() => {
  return {
    ...props.job,
    company_name: props.organisation.name
  }
})

const mainEl = ref<HTMLElement | null>(null)
defineExpose({
  apply: mainEl
})

onMounted(() => {
  ahoy.track("job_show", {
    id: props.job.id,
  })
})
</script>

<style scoped>
.job-description {
  font-size: 18px;
}

.job-description>>>h3 {
  font-size: 1.5rem;
  margin-top: 15px;
  line-height: 1.3;
}

.job-description>>>h4 {
  font-size: 1.2rem;
  margin-top: 15px;
  line-height: 1.3;
}

@media (max-width: 768px) {
  .job-description {
    font-size: 16px;
  }
}
</style>
