<template>
  <div class="position-relative border rounded p-3 card dark:bg-gray">
    <button class="btn btn-link position-absolute removeButton p-0" :title="title" :aria-label="title"
      style="right: 15px; top: 15px"
      @click.stop.prevent="jobIsAdded(props.job) ? removeBookmark(props.job) : bookmarkJob(props.job)">
      <Icon class="icon" :class="{ plus: !jobIsAdded(props.job) }" :path="mdiClose" :title="title" />
    </button>
    <div v-if="job.visible === false">
      <span class="badge bg-secondary fs-5">{{ $t("js.components.bookmark_button.nicht_mehr_verfuegbar") }}</span>
    </div>
    <a class="d-inline fs-5 me-4 mb-3 d-block text-decoration-none" :href="jobUrl">{{ job.title }}</a>
    <div class="job-card-company-row">
      <small v-if="previousAppliedOn" class="applied_badge badge badge-sm bg-success mt-2">
        {{
          $t("js.components.bookmark_button.beworben", {
            date: previousAppliedOn,
          })
        }}
      </small>
      <div v-else>
        <div v-if="job.organisation?.company_color"
          class="company-color company_color_cube d-inline-block rounded-sm me-2"
          :style="{ backgroundColor: job.organisation?.company_color }"></div>
        <p class="d-inline-block fw-bold">
          {{ job.organisation?.firma }}
        </p>
      </div>
    </div>
    <div v-if="job.visible">
      <div v-html="job.badge_html" class="mt-3"></div>

      <div v-html="job.description_no_links"
        class="mt-3 masked-overflow overflow-hidden job-description circle-list block-text"
        :class="{ 'masked-overflow--visible': isExpanded }"></div>

      <div class="btn-group mx-auto w-fit-content my-4 d-block">
        <button @click="isExpanded = !isExpanded" class="btn btn-primary btn-lg">
          <span v-if="isExpanded">{{ $t("js.components.filter_wizard.checkboxes.weniger") }}</span>
          <span v-else>{{ $t("js.components.filter_wizard.checkboxes.mehr") }}</span>
        </button>
        <button @click="applyOpen = !applyOpen" class="btn btn-apply btn-lg">
          <Icon :path="mdiChevronDoubleDown"
            :style="{ transform: applyOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s' }" />
          {{ $t("js.components.apply_card.apply_now_button") }}
        </button>
      </div>
      <transition name="smooth">
        <div class="p-4 bg-gray dark:bg-gray-600" v-if="applyOpen">
          <ApplyForm :job="job"></ApplyForm>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { bookmarkJob, removeBookmark, jobIsAdded } from "@/stores/cartStore"
import { Icon } from "@/elements"
import { mdiClose, mdiChevronDoubleDown } from "@mdi/js"
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
import { previousApplicationTo } from "@/components/apply/history"
import ApplyForm from "@/components/apply/ApplyForm.vue"

const props = defineProps<{
  job: merkliste.CombinedJobInfo
}>()

const jobUrl = props.job.job_details_path
const isExpanded = ref(false)
const applyOpen = ref(false)

const $t = useI18n().t

const title = computed(() => $t("js.components.bookmark_button.aus_merkliste_entfernen"))

const previousAppliedOn = computed(() => {
  const prevAp = previousApplicationTo(props.job.id.toString())
  if (!prevAp) {
    return null
  }

  // format date in locale date form
  const date = new Date(prevAp.applied_on)
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  return date.toLocaleDateString(undefined, options)
})
</script>

<style scoped>
.removeButton {
  top: 0;
  right: 0;
}

@media (prefers-color-scheme: dark) {
  a:link {
    color: #85cde0;
  }
}

.company-color {
  content: "";
  width: 1em;
  height: 1em;
}

.v-mdi {
  transition: rotate 0.2s ease-in-out;
}

.plus {
  rotate: 45deg;
}

.masked-overflow {
  --scrollbar-width: 8px;
  --mask-height: 5em;
  overflow-y: auto;
  height: 300px;
  padding-bottom: var(--mask-height);
  padding-right: 20px;
  --mask-image-content: linear-gradient(to bottom, black var(--mask-height), black calc(100% - var(--mask-height)), transparent);
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
  transition: all 0.2s ease-in-out;
}

.masked-overflow--visible {
  height: auto;
  mask-image: none;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: max-height 0.5s;
}

.smooth-enter,
.smooth-leave-to {
  max-height: 0;
}
</style>
