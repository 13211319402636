import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import $ from "@/utils/jquery";

export default class extends Controller {
  sections: HTMLElement[] = [];
  bullets: HTMLElement[] = [];
  isDesktop: boolean = false;

  connect() {
    this.isDesktop = $(window).width() > 1024;

    if (this.isDesktop) {
      $('body').sectionScroll({
        scrollDuration: 300,
        easing: 'easeInOutCubic',
        topOffset: 0,
      });

      this.sections = Array.from(document.querySelectorAll(".scrollable-section"));
      this.bullets = Array.from(document.querySelectorAll(".section-bullets li"));

      this.sections.forEach((section) => {
        useIntersection(this, { element: section, threshold: [0.5] });
      });
    }
  }

  appear(entry: IntersectionObserverEntry) {
    const index = this.sections.indexOf(entry.target as HTMLElement);
    if (index !== -1) {
      this.bullets[index].classList.add("active");
    }
  }

  disappear(entry: IntersectionObserverEntry) {
    const index = this.sections.indexOf(entry.target as HTMLElement);
    if (index !== -1) {
      this.bullets[index].classList.remove("active");
    }
  }
}
