<template lang="pug">
.timeline#timeline(ref="timeline")
  div(v-for="(event, index) in events" :key='index')
    history-event(
      :key='index'
      :class="index%2==0 ? 'left' : 'right'"
      :event='event'
      :isReached='isReached(index)'
      :id='index'
      :editable='editable'
    )
  .vertical-line(:style="lineStyle")
</template>


<script setup lang="ts">
import {
  ref,
  onMounted,
  computed,
  reactive,
  onBeforeUnmount,
} from "vue"
import type { PropType } from 'vue'
import HistoryEvent from './HistoryEvent.vue'

const props = defineProps({
  events: {
    type: Array as PropType<Array<HistoryEvent>>,
    required: true
  },
  lineColor: {
    type: String,
    default: '#23788f'
  },
  editable: {
    type: Boolean,
    default: false
  },
})

const eventsReached = ref<boolean[]>([])

const timeline = ref<HTMLElement | null>(null)

const inView = reactive<Set<number>>(new Set)

const cHeight = ref<Number>(0)

const lineHeight = computed(() => {
  return `${cHeight.value}px`
})

const lineStyle = computed(() => ({
  'background-color': props.lineColor,
  'height': lineHeight.value
}))

function upScroll(id: number) {
  return id == Math.max(...inView)
}

const ratio = ref<number>(0.5)

const callback = (entries: any) => {
  entries.forEach((entry: any) => {
    const id = parseInt(entry.target.id)
    const height = entry.boundingClientRect.height
    if (entry.intersectionRatio >= ratio.value && !eventsReached.value[id] && !upScroll(id)) {
      if (id == props.events.length - 1) {
        cHeight.value = timeline.value?.clientHeight || 0
      }
      else {
        cHeight.value = cHeight.value + height + 3
      }
      eventsReached.value[id] = true
      inView.add(id)
    }

    if (!entry.isIntersecting && upScroll(id)) {
      inView.delete(id)
      eventsReached.value[id] = false
      const tHeight = cHeight.value - height
      if (id == 0) {
        cHeight.value = 0
      }
      else {
        cHeight.value = tHeight
      }
    }
  })
}

const intObserver = new IntersectionObserver(callback, {
  root: document.getElementById('timeline'),
  rootMargin: "0px 0px -350px 0px",
  threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.9, 1],
})

// Array.from({length: 100}, (_, i) => parseFloat(`0.${i}`))

function isReached(index: number) {
  return !!eventsReached.value[index];
}

function observeEvents() {
  document.querySelectorAll(".timeline-container").forEach((el) => {
    intObserver.observe(el)
  })
}

onMounted(() => {
  if (window.innerWidth < 800) {
    ratio.value = 0.2
  }
  eventsReached.value = new Array(props.events.length).fill(false)
  observeEvents()
})

onBeforeUnmount(() => {
  intObserver.disconnect()
})

</script>

<style lang="scss">
@import "@/style/components/timeline.scss";

.activated {
  border-width: 2px;
  border-style: solid;
}
</style>
