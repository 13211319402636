// src/application.js
import { Application } from "@hotwired/stimulus"

import AutohideController from "./autohide_controller"
import CounttoController from "./countto_controller"
import SmoothscrollController from "./smoothscroll_controller"
import RangeSliderController from "./range_slider_controller"
import AhoyController from "./ahoy_controller"
import SlickController from "./slick_controller"
import SimilarJobsController from "./similar_jobs_controller"
import HideontopController from "./hideontop_controller"
import ChartsApplicationsController from "./charts/applications_controller"
import ChartsFeedbackController from "./charts/feedback_controller"
import ChartsSubscriptionsController from "./charts/subscriptions_controller"
import ChartsBaseController from "./charts/base_controller"
import ChartsHeatmapController from "./charts/heatmap_controller"
import ChartsTagcloudController from "./charts/tagcloud_controller"
import ChartsKanaleoPolarController from "./charts/kanaleo_polar_controller"
import ChartsRingvorlesungController from "./charts/ringvorlesung_controller"
import MapController from "./map_controller"
import ImpressionController from "./impression_controller"
import ShareController from "./share_controller"
import ChoicesController from "./choices_controller"
import ContactController from "./contact_controller"
import ScrollController from "./scroll_controller"
import JobwertSalaryBoxplotController from "./jobwert_salary_boxplot_controller"
import JobwertCompanyPieController from "./jobwert_company_pie_controller"
import JobwertAgePyramidController from "./jobwert_age_pyramid_controller"
import KanaleoAgePyramidController from "./kanaleo_age_pyramid_controller"
import KanaleoDegreeBarController from "./kanaleo_degree_bar_controller"
import KanaleoBehaviorBarController from "./kanaleo_behavior_bar_controller"
import AutoplayVideoController from "./autoplay_video_controller"
import FadeInItemsConstroller from "./fadein_items_controller"
import SectionScrollController from "./section_scroll_controller"

const Stimulus = Application.start()

Stimulus.register("autohide", AutohideController)
Stimulus.register("countto", CounttoController)
Stimulus.register("smoothscroll", SmoothscrollController)
Stimulus.register("range-slider", RangeSliderController)
Stimulus.register("ahoy", AhoyController)
Stimulus.register("slick", SlickController)
Stimulus.register("similar-jobs", SimilarJobsController)
Stimulus.register("hideontop", HideontopController)
Stimulus.register("charts--base", ChartsBaseController)
Stimulus.register("charts--applications", ChartsApplicationsController)
Stimulus.register("charts--feedback", ChartsFeedbackController)
Stimulus.register("charts--subscriptions", ChartsSubscriptionsController)
Stimulus.register("charts--heatmap", ChartsHeatmapController)
Stimulus.register("charts--tagcloud", ChartsTagcloudController)
Stimulus.register("charts--kanaleo-polar", ChartsKanaleoPolarController)
Stimulus.register("charts--ringvorlesung", ChartsRingvorlesungController)
Stimulus.register("map", MapController)
Stimulus.register("impression", ImpressionController)
Stimulus.register("share", ShareController)
Stimulus.register("choices", ChoicesController)
Stimulus.register("contact", ContactController)
Stimulus.register("scroll", ScrollController)
Stimulus.register("jobwert-salary-boxplot", JobwertSalaryBoxplotController)
Stimulus.register("jobwert-company-pie", JobwertCompanyPieController)
Stimulus.register("jobwert-age-pyramid", JobwertAgePyramidController)
Stimulus.register("kanaleo-age-pyramid", KanaleoAgePyramidController)
Stimulus.register("kanaleo-degree-bar", KanaleoDegreeBarController)
Stimulus.register("kanaleo-behavior-bar", KanaleoBehaviorBarController)
Stimulus.register("autoplay-video", AutoplayVideoController)
Stimulus.register("fadein-items", FadeInItemsConstroller)
Stimulus.register("section-scroll", SectionScrollController)

import JoblyticsFormController from "./joblytics/form_controller"
Stimulus.register("joblytics--form", JoblyticsFormController)

window.Stimulus = Stimulus
