<script setup lang="ts">
import { computed, onMounted } from "vue"
import { Icon } from "@/elements"
import { mdiBriefcasePlusOutline, mdiBriefcaseCheckOutline } from "@mdi/js"
import { bookmarkJob, removeBookmark, jobIsAdded } from "@/stores/cartStore"
import { previousApplicationTo } from "@/components/apply/history"
import { useI18n } from "vue-i18n"

const $t = useI18n().t

const props = withDefaults(defineProps<{
  job: BookmarkJob,
  variant?: string,
  class?: string,
  activeClass?: string,
}>(), {
  variant: "default",
  class: "btn btn-secondary w-100",
})

onMounted(() => {
});

const previousAppliedOn = computed(() => {
  const prevAp = previousApplicationTo(props.job.id.toString())
  if (!prevAp) {
    return null
  }

  // format date in locale date form
  const date = new Date(prevAp.applied_on)
  const options = { year: "numeric", month: "numeric", day: "numeric" }
  return date.toLocaleDateString(undefined, options)
})

const cssClasses = computed(() => {
  if (props.activeClass && isSelected.value) {
    return props.activeClass
  }
  return props.class
})

const isSelected = computed(() => jobIsAdded(props.job))
const toggle = () => {
  if (isSelected.value) {
    removeBookmark(props.job)
  } else {
    bookmarkJob(props.job)
  }
}
const title = computed(() => {
  if (isSelected.value) {
    return $t("js.components.bookmark_button.aus_merkliste_entfernen")
  } else {
    return $t("js.components.bookmark_button.in_merkliste_aufnehmen")
  }
})
const tooltip = computed(() => {
  return {
    content: title.value,
    class: "rounded shadow-lg px-4 py-2 bg-warning alert alert-warning",
    tippy: {
      trigger: "hover",
    }
  }
})
</script>

<template>
  <button :class="cssClasses" class="flex flex-colum align-items-center" @click.stop.prevent="toggle" :title="title"
    :aria-label="title" v-tooltip="tooltip">
    <Transition mode="out-in">
      <Icon v-if="isSelected" :path="mdiBriefcaseCheckOutline" :title="title" />
      <Icon v-else :path="mdiBriefcasePlusOutline" :title="title" />
    </Transition>
  </button>
  <small v-if="previousAppliedOn" class="badge bg-success badge-sm text-wrap mt-2 w-100">
    {{
      $t("js.components.bookmark_button.beworben", {
        date: previousAppliedOn,
      })
    }}
  </small>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0.5;
}

.btn:hover {
  color: #fff;
}

.btn {
  border: none;
}
</style>
