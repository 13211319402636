import { Controller } from "@hotwired/stimulus";

import Modal from "bootstrap/js/src/modal"

export default class extends Controller {
  static targets = ["modal"]
  declare modalTarget: HTMLDivElement
  connect() {
    console.log("Connected")
  }
  openModal(event: Event) {
    if (!this.urlValid()) {
      event.preventDefault()
      return
    }
    console.log("Open Modal", event)
    const modal = new Modal(this.modalTarget)
    modal.show()
  }

  urlValid() {
    const url = new FormData(this.element as HTMLFormElement).get("joblytics_report[url]")
    return url && url.match(/^https:\/\/.*/)
  }
}
